import axios from 'axios';

class Axios {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    instance: axios.AxiosInstance;

    constructor() {
        this.instance = axios.create({})
    }

    get(url: string) {
        return this.instance.get(url);
    }

    post(url: string, data: any) {
        return this.instance.post(url, data);
    }
}

export default new Axios()
