const Domain = {
    // pushServer: "http://127.0.0.1:4100",// 模拟发送弹幕服务器
    // danmuApiServer: "http://127.0.0.1:3000" // 游戏API服务器

    pushServer: "https://api.xdline.cn/simulatorapi",// 模拟发送弹幕服务器
    danmuApiServer: "https://api.xdline.cn/danmuapi" // 游戏API服务器
}

export default {
    pushApi: `${Domain.pushServer}/pushMessage`,
    pushKuaishouApi: `${Domain.pushServer}/kuaishouPushMessage`,


    applist: `${Domain.pushServer}/applist`,

    checkRoomId: `${Domain.danmuApiServer}/checkRoomId`,

    danmuDayRank: `${Domain.danmuApiServer}/dayrank`,
    danmuWeekRank: `${Domain.danmuApiServer}/weekrank`,

}
