export class StrUtils {

    static randomRange(min: number, max: number) {
        return Math.floor(Math.random() * (max - min + 1)) + min;
    }

    static dateTimeFormat(dt = new Date(), fmt = "yyyy-MM-dd hh:mm:ss") {  // author: meizz
        const o: { [key: string]: any } = {
            "M+": dt.getMonth() + 1,  // 月份
            "d+": dt.getDate(),  // 日
            "h+": dt.getHours(),  // 小时
            "m+": dt.getMinutes(),  // 分
            "s+": dt.getSeconds(),  // 秒
            "q+": Math.floor((dt.getMonth() + 3) / 3),  // 季度
            "S": dt.getMilliseconds()  // 毫秒
        };
        if (/(y+)/.test(fmt)) fmt = fmt.replace(RegExp.$1, (dt.getFullYear() + "").substr(4 - RegExp.$1.length));
        for (const k in o) {
            if (new RegExp("(" + k + ")").test(fmt)) {
                fmt = fmt.replace(RegExp.$1, (RegExp.$1.length === 1) ? (o[k]) : (("00" + o[k]).substr(("" + o[k]).length)));
            }
        }
        return fmt;
    }


    static getWeekDate(date: Date, fmt = 'yyyy-MM-dd') {
        const nowTime = date.getTime();
        const day = date.getDay();
        const oneDayTime = 24 * 60 * 60 * 1000;
        const mondayTime = nowTime - (day - 1) * oneDayTime;
        const sundayTime = nowTime + (7 - day) * oneDayTime;
        const monday = new Date(mondayTime);
        const sunday = new Date(sundayTime);

        const startdate = StrUtils.dateTimeFormat(monday, fmt);
        const enddate = StrUtils.dateTimeFormat(sunday, fmt);
        return [startdate, enddate];


    }

}


